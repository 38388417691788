import { graphql } from "gatsby"
import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import Portfolio from "../components/Portfolio/Portfolio"
import { Heading } from "../components/Hero"
import Video from "../components/Video/Video"
import Sizzle from "../videos/sizzle-short.mp4"

function About(props) {
  const post = props.data.allUniquePagesJson.nodes[0]
  const {
    heroSection: { content }
  } = post

  return (
    <Layout className="about-page" path={props.path}>
      <SEO title={post.metaTitle} description={post.metaDescription} />
      <Heading content={content} />
      <div className="sizzle-container">
        <Video src={Sizzle} />
      </div>
      <Portfolio bgColor="tan" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query aboutPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heroSection {
          content
        }
      }
    }
  }
`

export default About
